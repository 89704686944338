<template>
  <v-container>
    <SidebarBulk />
    <div class="pt-7 mt-7">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="12" md="2" xs="4">
          <v-dialog ref="dialog" v-model="modal" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-1"
                v-model="dates"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"

                v-on="on"
                color="#ff0090"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates"  scrollable :min="minDate()" :max="maxDate()" color="#ff0090">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="warning"
                @click="
                  dates = ''

                "
              >
                {{ $t("message.clear") }}
              </v-btn>
              <v-btn text color="#ff0090" @click="modal = false">
                {{ $t("message.cancel") }}
              </v-btn>
              <v-btn text color="#ff0090" @click="filteringBulk">
                {{ $t("message.ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <div v-if="bulkList.length == 0">
        <v-container>
          <div>
            <h3 style="color: grey" class="text-center">{{ err_msg_bulkMain }}</h3>
          </div>
        </v-container>
      </div>

      <v-row>
        <v-col
          lg="3"
          md="3"
          xs="6"
          class="bulk-parent"
          v-for="(list, i) in bulkList"
          :key="i"
        >
          <ProductBulk :data="list"/>
        </v-col>
        <div>
          <div style="backgroundColor: #E8EAF6; height: 5px; margin-bottom: 10px"></div>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SidebarBulk from "../../components/bulk/SidebarBulk.vue";
import ProductBulk from "../../components/bulk/ProductBulk.vue";
export default {
  name: "BulkMain",
  components: {
    SidebarBulk,
    ProductBulk,
  },
  data: () => ({
    dates: "",
    modal: false,
  }),
  computed: {
    bulkList() {
      return this.$store.state.bulk_module.bulkByDateResult;
    },
    err_msg_bulkMain() {
      return this.$store.state.bulk_module.err_bulkMain
    },
  },
  created() {
    this.dispatchBulk();
    this.changeLocale()
  },
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'ja'
    },
    dispatchBulk() {
      this.$store.dispatch("bulk_module/bulkByDate");
    },
    filteringBulk() {
      this.$refs.dialog.save(this.dates);
      this.$store.dispatch("bulk_module/bulkByDate", {
        date: this.dates === null ? "" : this.dates
      });
    },
    maxDate() {
      const days = 7
      const today = new Date()
      const over_days = new Date(today.getTime() + days*24*60*60*1000)
      const date =
        over_days.getFullYear() +
        "-" +
        ("0" + (over_days.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + over_days.getDate()).slice(-2);
      const timestamp = date;
      return timestamp;
    },
    minDate() {
      const days = 2
      const today = new Date()
      const over_days = new Date(today.getTime() + days*24*60*60*1000)
      const date =
        over_days.getFullYear() +
        "-" +
        ("0" + (over_days.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + over_days.getDate()).slice(-2);
      const timestamp = date;
      return timestamp;
    },
  },
};
</script>

<style>
.bulk-parent {
  position: relative;
  /* margin-top: 10px; */
}
.v-input--is-focused .v-input__slot {
  /* border: 2px solid red !important; */
  border-bottom-color: red!important;
}
</style>
